import styled from 'styled-components'

import ModuleContainer from '../section-container'

export const Container = styled(ModuleContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  gap: 16px;
  text-align: center;
`
