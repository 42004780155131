import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Headline from '@typeform/ginger/dist/components/typography/headline/v2'
import Text from '@typeform/ginger/dist/components/typography/text/v2'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import AnimatedContainer from 'components/atoms/animated-container'

import { slideAndFade } from '../animations'

import { Container } from './headline-and-text-module.styles'

const HeadlineAndTextModule = ({
  title,
  description,
  hasRichTitle,
  hasRichDescription,
}) => {
  return (
    <AnimatedContainer
      entryAnimations={slideAndFade}
      entryConfig={{ threshold: 0.3 }}
    >
      <Container>
        <Headline
          as='h2'
          largeViewportsFontSize={fontSize.x4Large}
          mediumViewportsFontSize={fontSize.x3Large}
          smallViewportsFontSize={fontSize.x2Large}
        >
          {hasRichTitle ? (
            <span dangerouslySetInnerHTML={{ __html: title }} />
          ) : (
            title
          )}
        </Headline>
        {description && (
          <Text
            largeViewportsFontSize={fontSize.large}
            mediumViewportsFontSize={fontSize.small}
            smallViewportsFontSize={fontSize.small}
          >
            {hasRichDescription ? (
              <span dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              description
            )}
          </Text>
        )}
      </Container>
    </AnimatedContainer>
  )
}

HeadlineAndTextModule.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  hasRichTitle: PropTypes.bool,
  hasRichDescription: PropTypes.bool,
}

HeadlineAndTextModule.defaultProps = {
  description: '',
  hasRichTitle: false,
  hasRichDescription: false,
}

HeadlineAndTextModule.displayName = 'HeadlineAndTextModule'

export default memo(HeadlineAndTextModule)
